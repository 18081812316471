import React, {Component} from 'react';
import {Row,Col} from "antd";
import FrontFooter from '../FrontStatic/common/Front-Footer';
import FrontWrapper from "../FrontStatic/common/FrontWrapper";

class Map extends Component {
    render() {
        return (
            <FrontWrapper location={this.props.location}>
                <div className="page-main page-current front-grading">
                    <main className="page-content" role="main">
                        <section className="section-container staticImageBlock our-service-bn contact-banner">
                            <Row className="">
                                <Col lg={24} xs={24} className="">
                                    <img src={require("./gujarati.jpg")} alt="Gujarati"/>
                                </Col>
                                <Col lg={24}xs={24} >
                                    <img src={require("./english.jpeg")} alt="english"/>
                                </Col>
                            </Row>
                        </section>
                        <FrontFooter/>
                    </main>
                </div>
           </FrontWrapper>
        );
    }
}

export default Map;
